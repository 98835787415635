/* titillium-web-200 - latin */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 200;
  src: local(""),
    url("../src/assets/titillium-web-v15-latin-200.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../src/assets/titillium-web-v15-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-300 - latin */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  src: local(""),
    url("../src/assets/titillium-web-v15-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../src/assets/titillium-web-v15-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-300italic - latin */
@font-face {
  font-family: "Titillium Web";
  font-style: italic;
  font-weight: 300;
  src: local(""),
    url("../src/assets/titillium-web-v15-latin-300italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../src/assets/titillium-web-v15-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-regular - latin */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../src/assets/titillium-web-v15-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../src/assets/titillium-web-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-600italic - latin */
@font-face {
  font-family: "Titillium Web";
  font-style: italic;
  font-weight: 600;
  src: local(""),
    url("../src/assets/titillium-web-v15-latin-600italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../src/assets/titillium-web-v15-latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-600 - latin */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("../src/assets/titillium-web-v15-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../src/assets/titillium-web-v15-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

#ie-warning {
  margin: 20px;
  font-family: "Titillium Web";
  display: none;
}
body {
  margin: 0;
  height: 100vh;
}
#root {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 868px) {
  #root {
    min-height: 100vh;
  }
}
@media print {
  body,
  #root {
    height: auto;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  @page {
    size: auto;
    margin: 20px;
  }
}
