@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 200;
  src: local(""), url("titillium-web-v15-latin-200.8d4311eb.woff2") format("woff2"), url("titillium-web-v15-latin-200.06db439a.woff") format("woff");
}

@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("titillium-web-v15-latin-300.a13afa2c.woff2") format("woff2"), url("titillium-web-v15-latin-300.5be71d78.woff") format("woff");
}

@font-face {
  font-family: Titillium Web;
  font-style: italic;
  font-weight: 300;
  src: local(""), url("titillium-web-v15-latin-300italic.886d4dd8.woff2") format("woff2"), url("titillium-web-v15-latin-300italic.85c17e69.woff") format("woff");
}

@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("titillium-web-v15-latin-regular.1ed98299.woff2") format("woff2"), url("titillium-web-v15-latin-regular.097b2489.woff") format("woff");
}

@font-face {
  font-family: Titillium Web;
  font-style: italic;
  font-weight: 600;
  src: local(""), url("titillium-web-v15-latin-600italic.72091a52.woff2") format("woff2"), url("titillium-web-v15-latin-600italic.e25e2e97.woff") format("woff");
}

@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("titillium-web-v15-latin-600.ce83c8c7.woff2") format("woff2"), url("titillium-web-v15-latin-600.bc604b94.woff") format("woff");
}

#ie-warning {
  margin: 20px;
  font-family: Titillium Web;
  display: none;
}

body {
  height: 100vh;
  margin: 0;
}

#root {
  height: 100%;
  flex-direction: column;
  margin: 0;
  display: flex;
}

@media (max-width: 868px) {
  #root {
    min-height: 100vh;
  }
}

@media print {
  body, #root {
    height: auto;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @page {
    size: auto;
    margin: 20px;
  }
}

/*# sourceMappingURL=index.671f8c90.css.map */
